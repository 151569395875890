<template>
  <div
    class="modal"
    :class="addClass"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalMovieDetail"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered collapsed" role="document">
      <div class="modal-content">
        <Loader v-if="showLoader" display="relative" />

        <slot v-if="!showLoader"></slot>
      </div>
    </div>

    <button
      v-if="buttonCloseFlag"
      type="button"
      class="close"
      aria-label="Close"
    >
      <span aria-hidden="true"><i class="zmdi zmdi-close"></i></span>
    </button>
  </div>
</template>

<script>
export default {
  name: "Modal",
  data() {
    return {
      buttonCloseFlag: true,
    };
  },

  components: {
    Loader: () => import("@/components/Loader.vue"),
  },

  props: {
    addClass: { type: String, required: true },
    showLoader: { type: Boolean, required: false, default: false },
  },

  mounted() {
    if (this.addClass === "modal-pin") {
      this.$bus.$emit("modal-pin:mounted");
      this.$bus.$on("hiddenButtonModal", this.checkCloseButton);
    }
  },
  methods: {
    checkCloseButton(set) {
      this.buttonCloseFlag = set;
    },
  },
};
</script>
